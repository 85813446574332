var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      staticClass: "ma-0 pa-0",
      attrs: { fluid: "" },
    },
    [
      _c("v-data-table", {
        staticClass: "rounded-0",
        attrs: {
          headers: _vm.headers,
          height: _vm.tableHeight,
          items: _vm.items,
          "loading-text": "Searching Available Data...Please Wait",
          loading: _vm.loading,
          "fixed-header": "",
          "hide-default-footer": "",
          "group-by": "promo_item_group.id",
          "item-key": "id",
          "loader-height": "5",
          "no-data-text": "No Data to Display",
          dense: "",
          "items-per-page": _vm.pageSize,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function () {
                return [
                  _c("v-divider"),
                  _c(
                    "v-row",
                    { attrs: { align: "center", dense: "" } },
                    [
                      _c(
                        "v-btn-toggle",
                        {
                          staticClass: "ml-3 my-2",
                          attrs: { dense: "", mandatory: "" },
                          model: {
                            value: _vm.toggle,
                            callback: function ($$v) {
                              _vm.toggle = $$v
                            },
                            expression: "toggle",
                          },
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            class: _vm.isPromoSearch
                                              ? "green"
                                              : "",
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: _vm.isPromoSearch
                                                  ? "white"
                                                  : "",
                                              },
                                            },
                                            [_vm._v("mdi-currency-usd")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("Promotional Item")])]
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            class: !_vm.isPromoSearch
                                              ? "red"
                                              : "",
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: !_vm.isPromoSearch
                                                  ? "white"
                                                  : "",
                                              },
                                            },
                                            [_vm._v("mdi-currency-usd-off")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("Non-Promotional Item")])]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "ml-1 my-2", attrs: { md: "2" } },
                        [
                          _c("v-select", {
                            class: _vm.body,
                            attrs: {
                              placeholder: "Search By",
                              "append-icon": ":",
                              items: _vm.searchParams,
                              "item-text": "description",
                              "return-object": "",
                              dense: "",
                              "hide-details": "",
                              "menu-props": {
                                closeOnClick: true,
                                bottom: true,
                                offsetY: true,
                              },
                            },
                            model: {
                              value: _vm.search.key,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "key", $$v)
                              },
                              expression: "search.key",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "ml-1 my-2", attrs: { md: "3" } },
                        [
                          _c("v-text-field", {
                            class: _vm.body,
                            attrs: {
                              placeholder: _vm.search.key
                                ? `Search By ${_vm.search.key.description}`
                                : "Select Search Field",
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              "background-color": "input",
                            },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                _vm.isPromoSearch
                                  ? _vm.promoItemLookup()
                                  : _vm.nonPromoItemLookup()
                              },
                            },
                            model: {
                              value: _vm.search.value,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "value", $$v)
                              },
                              expression: "search.value",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "ml-1 my-2",
                                        attrs: { color: "primary" },
                                        on: {
                                          click: function ($event) {
                                            _vm.isPromoSearch
                                              ? _vm.promoItemLookup()
                                              : _vm.nonPromoItemLookup()
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "white--text" },
                                        [_vm._v("mdi-magnify")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.isPromoSearch
                                  ? "Promotional Search"
                                  : "Non Promotional Search"
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c("v-spacer"),
                      _vm.isPromoSearch
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "success mr-5 pr-5",
                              attrs: { disabled: !_vm.pendingAdditions },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.linkPromo()
                                },
                              },
                            },
                            [_vm._v(" ADD ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: `group.header`,
              fn: function ({ group, items, headers }) {
                return [
                  _vm.isPromoSearch
                    ? _c(
                        "td",
                        { attrs: { colspan: headers.length } },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pl-4 mr-0 pr-0",
                                  attrs: { dense: "", cols: "1" },
                                },
                                [
                                  _vm.feature.promo_item_group_ids.includes(
                                    group
                                  )
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "success" } },
                                        [_vm._v(" mdi-check ")]
                                      )
                                    : [
                                        _c(
                                          "v-checkbox",
                                          _vm._g(
                                            {
                                              staticClass: "my-auto mx-0",
                                              attrs: {
                                                color: "primary",
                                                "hide-details": "",
                                                value: group,
                                                ripple: false,
                                              },
                                              model: {
                                                value: _vm.pendingAddItems,
                                                callback: function ($$v) {
                                                  _vm.pendingAddItems = $$v
                                                },
                                                expression: "pendingAddItems",
                                              },
                                            },
                                            _vm.on
                                          )
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _c(
                                "v-col",
                                { staticStyle: { "white-space": "nowrap" } },
                                [
                                  _c("span", [
                                    _c("b", [_vm._v("Promo Item Group")]),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(items[0].promo_item_group.name) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticStyle: { "white-space": "nowrap" } },
                                [
                                  _c("span", [
                                    _c("b", [_vm._v("Promo Name")]),
                                    _c("br"),
                                  ]),
                                  items[0].promotion.name
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(items[0].promotion.name) +
                                            " "
                                        ),
                                      ])
                                    : _c("span", [_vm._v("N/A")]),
                                ]
                              ),
                              _c("v-col", [
                                _c("span", [
                                  _c("b", [_vm._v("Promo Type")]),
                                  _c("br"),
                                ]),
                                items[0].kv_name
                                  ? _c("span", [
                                      _vm._v(
                                        " " + _vm._s(items[0].kv_name) + " "
                                      ),
                                    ])
                                  : _c("span", [_vm._v("N/A")]),
                              ]),
                              _c("v-col", [
                                _c("span", [
                                  _c("b", [_vm._v("Promo Start Date")]),
                                  _c("br"),
                                ]),
                                items[0].promotion.start_dt
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(items[0].promotion.start_dt) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", [_vm._v("N/A")]),
                              ]),
                              _c("v-col", [
                                _c("span", [
                                  _c("b", [_vm._v("Promo End Date")]),
                                  _c("br"),
                                ]),
                                items[0].promotion.end_dt
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(items[0].promotion.end_dt) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", [_vm._v("N/A")]),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { align: "right" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        small: "",
                                                        icon: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openLink(
                                                            items[0]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        " mdi-open-in-new "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_c("span", [_vm._v("Promotion Portal")])]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.amount_off`,
              fn: function ({ item }) {
                return [
                  item.amount_off
                    ? _c("span", [
                        _vm._v(
                          "$" +
                            _vm._s(_vm.formatCurrencyDisplay(item.amount_off))
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]
              },
            },
            {
              key: `item.promotion_price`,
              fn: function ({ item }) {
                return [
                  item.promotion_price
                    ? _c("span", [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(item.promotion_price)
                            )
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]
              },
            },
            {
              key: `item.rebate_amount`,
              fn: function ({ item }) {
                return [
                  item.promo_item_group.rebate_amount
                    ? _c("span", [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promo_item_group.rebate_amount
                              )
                            )
                        ),
                      ])
                    : item.promotion.rebate_amount
                    ? _c("span", [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promotion.rebate_amount
                              )
                            )
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]
              },
            },
            {
              key: `item.item.cost`,
              fn: function ({ item }) {
                return [
                  item.item.future_cost == item.item.cost &&
                  item.item.future_cost_date <= _vm.selectedAd.ad_end_date
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "primary--text font-weight-bold",
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " $" +
                                            _vm._s(
                                              _vm.formatCurrencyDisplay(
                                                item.item.cost
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _vm._v(
                            " This item has a cost change on " +
                              _vm._s(
                                _vm.$dateConfig.formatDate(
                                  item.item.future_cost_date
                                )
                              ) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " The new cost of $" +
                              _vm._s(
                                _vm.formatCurrencyDisplay(item.item.cost)
                              ) +
                              " will be used on this ad "
                          ),
                        ]
                      )
                    : _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(item.item.current_cost)
                            ) +
                            " "
                        ),
                      ]),
                ]
              },
            },
            {
              key: `item.retail_price`,
              fn: function ({ item }) {
                return [
                  item.promo_item_group.retail_price
                    ? _c("span", [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promo_item_group.retail_price
                              )
                            )
                        ),
                      ])
                    : item.promotion.retail_price
                    ? _c("span", [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promotion.retail_price
                              )
                            )
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]
              },
            },
            {
              key: `item.billback_allowance`,
              fn: function ({ item }) {
                return [
                  item.accrual_amt
                    ? _c("span", [
                        _vm._v(
                          "$" +
                            _vm._s(_vm.formatCurrencyDisplay(item.accrual_amt))
                        ),
                      ])
                    : item.promo_item_group.billback_allowance
                    ? _c("span", [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promo_item_group.billback_allowance
                              )
                            )
                        ),
                      ])
                    : item.promotion.billback_allowance
                    ? _c("span", [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promotion.billback_allowance
                              )
                            )
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]
              },
            },
            {
              key: `item.amap_allowance`,
              fn: function ({ item }) {
                return [
                  item.promo_item_group.amap_allowance
                    ? _c("span", [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promo_item_group.amap_allowance
                              )
                            )
                        ),
                      ])
                    : item.promotion.amap_allowance
                    ? _c("span", [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promotion.amap_allowance
                              )
                            )
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]
              },
            },
            {
              key: `item.early_buy_allowance`,
              fn: function ({ item }) {
                return [
                  item.promo_item_group.early_buy_allowance
                    ? _c("span", [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promo_item_group.early_buy_allowance
                              )
                            )
                        ),
                      ])
                    : item.promotion.early_buy_allowance
                    ? _c("span", [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promotion.early_buy_allowance
                              )
                            )
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]
              },
            },
            {
              key: `item.item.sale_price`,
              fn: function ({ item }) {
                return [
                  item.item.sale_price
                    ? _c("span", [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(item.item.sale_price)
                            )
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]
              },
            },
            {
              key: `item.promo_info`,
              fn: function () {
                return [_c("span", [_vm._v("-")])]
              },
              proxy: true,
            },
            {
              key: `item.cost`,
              fn: function ({ item }) {
                return [
                  item.future_cost != item.cost &&
                  item.future_cost_date <= _vm.selectedAd.ad_end_date
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "primary--text font-weight-bold",
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " $" +
                                            _vm._s(
                                              _vm.formatCurrencyDisplay(
                                                item.future_cost
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _vm._v(
                            " This item has a cost change on " +
                              _vm._s(
                                _vm.$dateConfig.formatDate(
                                  item.future_cost_date
                                )
                              ) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " The new cost of $" +
                              _vm._s(
                                _vm.formatCurrencyDisplay(item.future_cost)
                              ) +
                              " will be used on this ad "
                          ),
                        ]
                      )
                    : _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(_vm.formatCurrencyDisplay(item.cost)) +
                            " "
                        ),
                      ]),
                ]
              },
            },
            {
              key: `item.unit_cost`,
              fn: function ({ item }) {
                return [
                  item.item.qty_per_case
                    ? _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(_vm.calcUnitCost(item))
                            ) +
                            " "
                        ),
                      ])
                    : _c("span", [_vm._v(" - ")]),
                ]
              },
            },
            {
              key: `item.start_dt`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_vm.formatDateDisplay(item.start_dt)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.end_dt`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_vm.formatDateDisplay(item.end_dt)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.count_price`,
              fn: function (props) {
                return [
                  _c(
                    "v-row",
                    { staticStyle: { height: "60px" }, attrs: { dense: "" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "close-on-click": false,
                                bottom: "",
                                right: "",
                                absolute: "",
                                "nudge-top": "-12px",
                                "nudge-left": "16px",
                                "offset-y": "",
                                "max-width": "300px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "edit-menu-container",
                                                style:
                                                  !_vm.nonPromoItems.includes(
                                                    props.item.item_id
                                                  )
                                                    ? "cursor: pointer"
                                                    : "cursor: default",
                                                on: {
                                                  click: function ($event) {
                                                    !_vm.nonPromoItems.includes(
                                                      props.item.item_id
                                                    )
                                                      ? _vm.open(props.item)
                                                      : null
                                                  },
                                                },
                                              },
                                              "div",
                                              attrs,
                                              false
                                            ),
                                            !_vm.nonPromoItems.includes(
                                              props.item.item_id
                                            )
                                              ? on
                                              : null
                                          ),
                                          [
                                            _c(
                                              "span",
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-caption ma-0 pa-0",
                                                  },
                                                  [_vm._v("Qty")]
                                                ),
                                                !_vm.nonPromoItems.includes(
                                                  props.item.item_id
                                                )
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "mx-0 pa-0",
                                                        attrs: {
                                                          depressed: "",
                                                          small: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              props.item
                                                                .multiplier
                                                                ? props.item
                                                                    .multiplier
                                                                : "-"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ml-0 pa-0",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              props.item
                                                                .multiplier
                                                                ? props.item
                                                                    .multiplier
                                                                : "-"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.editingQuantity,
                                callback: function ($$v) {
                                  _vm.editingQuantity = $$v
                                },
                                expression: "editingQuantity",
                              },
                            },
                            [
                              _vm.editItem &&
                              _vm.editItem.item_id === props.item.item_id
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "pt-2 px-4",
                                      staticStyle: { "z-index": "97" },
                                      attrs: { tile: "", flat: "" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "8px" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "subtitle-2" },
                                            [_vm._v("Edit Quantity")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "qty-controls ml-13" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "my-auto py-0 my-0",
                                              attrs: {
                                                icon: "",
                                                "x-small": "",
                                                fab: "",
                                                dense: "",
                                                color: "primary",
                                                disabled:
                                                  _vm.editItem.multiplier === 1,
                                              },
                                              on: { click: _vm.decreaseQty },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-minus"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c("v-text-field", {
                                            ref: "multiplier",
                                            staticClass: "pt-2 mt-0",
                                            attrs: {
                                              rules: [
                                                (v) => !!v || "Count Required",
                                                (v) =>
                                                  !(v < 0) ||
                                                  "Cannot have negative quantity",
                                              ],
                                              placeholder: "Qty",
                                              min: "1",
                                              "single-line": "",
                                              autofocus: "",
                                              "hide-spin-buttons": "",
                                              type: "number",
                                            },
                                            model: {
                                              value: _vm.editItem.multiplier,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editItem,
                                                  "multiplier",
                                                  $$v
                                                )
                                              },
                                              expression: "editItem.multiplier",
                                            },
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "my-auto py-0 my-0",
                                              attrs: {
                                                icon: "",
                                                "x-small": "",
                                                fab: "",
                                                dense: "",
                                                color: "primary",
                                              },
                                              on: { click: _vm.increaseQty },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-plus"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-actions",
                                        { staticClass: "pt-0 px-0" },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "", small: "" },
                                              on: { click: _vm.cancel },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "primary",
                                                loading: _vm.loading,
                                                disabled:
                                                  !_vm.editItem.multiplier ||
                                                  _vm.editItem.multiplier < 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.save(
                                                    "multiplier",
                                                    props.item
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Save ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "accent",
                                                loading: _vm.loading,
                                                disabled:
                                                  !_vm.editItem.multiplier ||
                                                  _vm.editItem.multiplier < 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.applyAll(
                                                    "multiplier"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Apply All ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "ml-3 mt-4", attrs: { cols: "1" } },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-at"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "close-on-click": false,
                                bottom: "",
                                right: "",
                                absolute: "",
                                "nudge-top": "-12px",
                                "nudge-left": "16px",
                                "offset-y": "",
                                "max-width": "300px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "edit-menu-container",
                                                style:
                                                  !_vm.nonPromoItems.includes(
                                                    props.item.item_id
                                                  )
                                                    ? "cursor: pointer"
                                                    : "cursor: default",
                                                on: {
                                                  click: function ($event) {
                                                    !_vm.nonPromoItems.includes(
                                                      props.item.item_id
                                                    )
                                                      ? _vm.open(props.item)
                                                      : null
                                                  },
                                                },
                                              },
                                              "div",
                                              attrs,
                                              false
                                            ),
                                            !_vm.nonPromoItems.includes(
                                              props.item.item_id
                                            )
                                              ? on
                                              : null
                                          ),
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "mx-0 pa-0" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-caption ma-0 pa-0",
                                                  },
                                                  [_vm._v("Sale Price")]
                                                ),
                                                _vm._v(
                                                  " $" +
                                                    _vm._s(
                                                      _vm.formatCurrencyDisplay(
                                                        props.item.sale_price
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            !_vm.nonPromoItems.includes(
                                              props.item.item_id
                                            )
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "ml-0 mt-4 pa-0",
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("mdi-pencil")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.editingSalePrice,
                                callback: function ($$v) {
                                  _vm.editingSalePrice = $$v
                                },
                                expression: "editingSalePrice",
                              },
                            },
                            [
                              _vm.editItem &&
                              _vm.editItem.item_id === props.item.item_id
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "pt-2 px-4",
                                      staticStyle: { "z-index": "98" },
                                      attrs: { tile: "", flat: "" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "8px" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "subtitle-2" },
                                            [_vm._v("Edit Sale Price")]
                                          ),
                                        ]
                                      ),
                                      _c("v-text-field", {
                                        ref: "sale_price",
                                        staticClass: "pt-0 mt-0",
                                        attrs: {
                                          rules: [
                                            _vm.isValidPrice,
                                            (v) =>
                                              !(v < 0) ||
                                              "Cannot have negative value.",
                                          ],
                                          autofocus: "",
                                          type: "number",
                                          "hide-spin-buttons": "",
                                          prefix: "$",
                                        },
                                        model: {
                                          value: _vm.editItem.sale_price,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editItem,
                                              "sale_price",
                                              $$v
                                            )
                                          },
                                          expression: "editItem.sale_price",
                                        },
                                      }),
                                      _c(
                                        "v-card-actions",
                                        { staticClass: "pt-0 px-0" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "", small: "" },
                                              on: { click: _vm.cancel },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "primary",
                                                loading: _vm.loading,
                                                disabled:
                                                  !_vm.editItem.sale_price ||
                                                  _vm.editItem.sale_price < 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.save(
                                                    "sale_price",
                                                    props.item
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Save ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "accent",
                                                loading: _vm.loading,
                                                disabled:
                                                  !_vm.editItem.sale_price ||
                                                  _vm.editItem.sale_price < 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.applyAll(
                                                    "sale_price"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Apply All ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.net_unit_cost`,
              fn: function (props) {
                return [
                  _c(
                    "v-row",
                    {
                      staticClass: "ml-0 pa-0",
                      staticStyle: { height: "60px" },
                      attrs: { dense: "" },
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "close-on-click": false,
                                bottom: "",
                                absolute: "",
                                "nudge-top": "-12px",
                                "nudge-left": "16px",
                                "offset-y": "",
                                "max-width": "300px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "edit-menu-container",
                                                style:
                                                  !_vm.nonPromoItems.includes(
                                                    props.item.item_id
                                                  )
                                                    ? "cursor: pointer"
                                                    : "cursor: default",
                                                on: {
                                                  click: function ($event) {
                                                    !_vm.nonPromoItems.includes(
                                                      props.item.item_id
                                                    )
                                                      ? _vm.open(props.item)
                                                      : null
                                                  },
                                                },
                                              },
                                              "div",
                                              attrs,
                                              false
                                            ),
                                            !_vm.nonPromoItems.includes(
                                              props.item.item_id
                                            )
                                              ? on
                                              : null
                                          ),
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "ml-0 mt-4 pa-0" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      props.item.net_unit_cost
                                                        ? "$" +
                                                            _vm.formatCurrencyDisplay(
                                                              props.item
                                                                .net_unit_cost
                                                            )
                                                        : "-"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            !_vm.nonPromoItems.includes(
                                              props.item.item_id
                                            )
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "ml-2 mt-4 mb-1 pa-0",
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("mdi-pencil")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.editingNetUnitCost,
                                callback: function ($$v) {
                                  _vm.editingNetUnitCost = $$v
                                },
                                expression: "editingNetUnitCost",
                              },
                            },
                            [
                              _vm.editItem &&
                              _vm.editItem.item_id === props.item.item_id
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "pt-2 px-4",
                                      staticStyle: { "z-index": "99" },
                                      attrs: { tile: "", flat: "" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "8px" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "subtitle-2" },
                                            [_vm._v("Edit Net Unit Cost")]
                                          ),
                                        ]
                                      ),
                                      _c("v-text-field", {
                                        ref: "net_unit_cost",
                                        staticClass: "pt-0 mt-0",
                                        attrs: {
                                          rules: [
                                            _vm.isValidPrice,
                                            (v) =>
                                              !(v < 0) ||
                                              "Cannot have negative value.",
                                          ],
                                          autofocus: "",
                                          type: "number",
                                          "hide-spin-buttons": "",
                                          prefix: "$",
                                        },
                                        model: {
                                          value: _vm.editItem.net_unit_cost,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editItem,
                                              "net_unit_cost",
                                              $$v
                                            )
                                          },
                                          expression: "editItem.net_unit_cost",
                                        },
                                      }),
                                      _c(
                                        "v-card-actions",
                                        { staticClass: "pt-0 px-0" },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "", small: "" },
                                              on: { click: _vm.cancel },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "primary",
                                                loading: _vm.loading,
                                                disabled:
                                                  !_vm.editItem.net_unit_cost ||
                                                  _vm.editItem.net_unit_cost <
                                                    0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.save(
                                                    "net_unit_cost",
                                                    props.item
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Save ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "accent",
                                                loading: _vm.loading,
                                                disabled:
                                                  !_vm.editItem.net_unit_cost ||
                                                  _vm.editItem.net_unit_cost <
                                                    0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.applyAll(
                                                    "net_unit_cost"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Apply All ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.gross_profit`,
              fn: function ({ item }) {
                return [
                  item.multiplier && item.sale_price && item.net_unit_cost
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                _vm.calcNonPromoGP(item)
                              )
                            ) +
                            "% "
                        ),
                      ])
                    : _c("span", [_vm._v(" - ")]),
                ]
              },
            },
            {
              key: `item.related`,
              fn: function ({ item }) {
                return [
                  item[_vm.tenantLinkedProperty]
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-tooltip",
                            {
                              staticClass: "mx-auto",
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                icon: "",
                                                small: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.viewRelatedItems(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "mx-auto" },
                                              [_vm._v("mdi-view-list")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v("Related Items")])]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  !_vm.nonPromoItems.includes(item.item_id)
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          staticStyle: { height: "25px", width: "25px" },
                          attrs: {
                            fab: "",
                            depressed: "",
                            disabled:
                              !item.multiplier ||
                              !item.sale_price ||
                              !item.net_unit_cost,
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addItem(item)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-plus"),
                          ]),
                        ],
                        1
                      )
                    : _c("v-icon", { attrs: { color: "green" } }, [
                        _vm._v(" mdi-check "),
                      ]),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _vm.showRelatedItemsModal
        ? _c("RelatedItemsModal", {
            attrs: {
              item: _vm.currentItem,
              currentItems: _vm.feature.non_promo_items,
              tableItems: _vm.items,
              readOnly: false,
              itemSearch: true,
            },
            on: {
              search: _vm.searchItems,
              cancel: function ($event) {
                _vm.showRelatedItemsModal = false
              },
            },
            model: {
              value: _vm.showRelatedItemsModal,
              callback: function ($$v) {
                _vm.showRelatedItemsModal = $$v
              },
              expression: "showRelatedItemsModal",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }